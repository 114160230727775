import './utils/i18n';

import {
  ChartBarIcon,
  CursorArrowRippleIcon,
  DocumentTextIcon,
  PresentationChartLineIcon,
  UserGroupIcon,
  UserIcon,
} from '@heroicons/react/24/solid';
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import SidebarMenu from './components/SidebarMenu';
import { useAuth } from './context/AuthContext';
import Analytics from './pages/Analytics';
import ArticleDetail from './pages/ArticleDetail';
import Articles from './pages/Articles';
import ClickDetail from './pages/ClickDetail';
import Clicks from './pages/Clicks';
import Login from './pages/Login';
import MyProfile from './pages/MyProfile';
import Overview from './pages/Overview';
import Register from './pages/Register';
import UserDetail from './pages/UserDetail';
import Users from './pages/Users';

export interface RouteType {
  name: string;
  url: string;
  component?: React.ReactNode;
}

const App: React.FC = () => {
  const routes: Array<RouteType> = [
    { name: 'Overview', url: '/', component: <Overview /> },
    { name: 'Articles', url: '/articles', component: <Articles /> },
    {
      name: 'Articles Details',
      url: '/articles/:id',
      component: <ArticleDetail />,
    },
    { name: 'Users', url: '/users', component: <Users /> },
    {
      name: 'User Details',
      url: '/users/:id',
      component: <UserDetail />,
    },
    { name: 'CLicks', url: '/clicks', component: <Clicks /> },
    { name: 'Click Details', url: '/clicks/:id', component: <ClickDetail /> },
    { name: 'My Profile', url: '/my-profile', component: <MyProfile /> },
    { name: 'Register', url: '/register', component: <Register /> },
    { name: 'Analytics', url: '/analytics', component: <Analytics /> },
  ];

  const primaryMenuItems: Array<{
    name: string;
    url: string;
    icon?: React.ElementType;
  }> = [
    { name: 'Overview', url: '/', icon: PresentationChartLineIcon },
    { name: 'Articles', url: '/articles', icon: DocumentTextIcon },
    { name: 'Users', url: '/users', icon: UserIcon },
    { name: 'Personas', url: '/personas', icon: UserGroupIcon },
    { name: 'Analytics', url: '/analytics', icon: ChartBarIcon },
    { name: 'Clicks', url: '/clicks', icon: CursorArrowRippleIcon },
  ];

  const secondaryMenuItems: {
    title: string;
    items: Array<{
      name: string;
      url: string;
      icon?: React.ElementType;
    }>;
  } = {
    title: 'Websites',
    items: [
      { name: 'Forbes.cz', url: 'https://www.forbes.cz' },
      { name: 'Forbes.sk', url: 'https://www.forbes.sk' },
      { name: 'Forbes.hu', url: 'https://www.forbes.hu' },
    ],
  };

  const { isLoggedIn } = useAuth();

  return (
    <Router>
      {isLoggedIn ? (
        <SidebarMenu
          routes={routes}
          primaryMenuItems={primaryMenuItems}
          secondaryMenuItems={secondaryMenuItems}
        />
      ) : (
        <Routes>
          <Route path="/register" element={<Register />} />
          <Route path="*" element={<Login />} />
        </Routes>
      )}
    </Router>
  );
};

export default App;
