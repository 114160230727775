import {
  ArrowRightStartOnRectangleIcon,
  BookOpenIcon,
  CodeBracketIcon,
  Cog8ToothIcon,
  UserIcon,
} from '@heroicons/react/24/solid';
import { useEffect } from 'react';
import {
  Link,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router-dom';

import { RouteType } from '../App';
import { ReactComponent as ForbesLogo } from '../assets/icons/f-logo.svg';
import { useAuth } from '../context/AuthContext';
import NotFound from '../pages/NotFound';
import { Avatar } from './catalyst-ui/avatar';
import {
  Dropdown,
  DropdownButton,
  DropdownDivider,
  DropdownItem,
  DropdownLabel,
  DropdownMenu,
} from './catalyst-ui/dropdown';
import {
  Navbar,
  NavbarItem,
  NavbarSection,
  NavbarSpacer,
} from './catalyst-ui/navbar';
import {
  Sidebar,
  SidebarBody,
  SidebarDivider,
  SidebarFooter,
  SidebarHeader,
  SidebarItem,
  SidebarLabel,
  SidebarSection,
} from './catalyst-ui/sidebar';
import { SidebarLayout } from './catalyst-ui/sidebar-layout';

type MenuItem = {
  name: string;
  url: string;
  icon?: React.ElementType;
};

export interface SidebarMenuProps {
  routes: Array<RouteType>;
  primaryMenuItems: Array<MenuItem>;
  secondaryMenuItems: {
    title?: string;
    items: Array<MenuItem>;
  };
}

const SidebarMenu: React.FC<SidebarMenuProps> = ({
  routes,
  primaryMenuItems,
  secondaryMenuItems,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { logout, user } = useAuth();

  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  useEffect(() => {
    const accessToken = localStorage.getItem('accessToken');
    if (!accessToken) {
      logout();
      navigate('/login');
    }
  }, [logout, navigate]);

  return (
    <SidebarLayout
      navbar={
        <Navbar>
          <div className="ml-5 flex items-center">
            <ForbesLogo className="size-6" />
            <span className="ml-2">AI Recommendation</span>
          </div>
          <NavbarSpacer />
          <NavbarSection>
            <Dropdown>
              <DropdownButton as={NavbarItem}>
                <Avatar
                  src={user?.profilePicture}
                  initials={`${user?.firstName[0]}${user?.lastName[0]}`}
                  className="size-12 bg-zinc-900 text-white"
                />
              </DropdownButton>
              <DropdownMenu className="min-w-64" anchor="bottom end">
                <DropdownItem onClick={() => navigate('/my-profile')}>
                  <UserIcon />
                  <DropdownLabel>My profile</DropdownLabel>
                </DropdownItem>
                <DropdownItem href="/settings">
                  <Cog8ToothIcon />
                  <DropdownLabel>Settings</DropdownLabel>
                </DropdownItem>

                <DropdownDivider />
                <DropdownItem onClick={handleLogout}>
                  <ArrowRightStartOnRectangleIcon />
                  <DropdownLabel>Sign out</DropdownLabel>
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </NavbarSection>
        </Navbar>
      }
      sidebar={
        <Sidebar>
          <SidebarHeader>
            <SidebarSection className="max-lg:hidden">
              <SidebarLabel>
                <SidebarLabel className="flex">
                  <ForbesLogo className="size-6" />
                  <span className="ml-2">AI Recommendation</span>
                </SidebarLabel>
              </SidebarLabel>
            </SidebarSection>
          </SidebarHeader>
          <SidebarBody>
            <SidebarSection>
              <SidebarSection>
                {primaryMenuItems &&
                  primaryMenuItems.map((item, index) => {
                    const isCurrent = location.pathname === item.url;

                    return (
                      <Link
                        to={item.url}
                        className="cursor-pointer"
                        key={index}
                      >
                        <SidebarItem current={isCurrent}>
                          <SidebarLabel className="flex items-center">
                            {item.icon && <item.icon className="mr-2 h-6" />}
                            {item.name}
                          </SidebarLabel>
                        </SidebarItem>
                      </Link>
                    );
                  })}
              </SidebarSection>
            </SidebarSection>

            <SidebarSection>
              {secondaryMenuItems?.title && (
                <SidebarItem>
                  <SidebarLabel className="text-xs text-zinc-500">
                    {secondaryMenuItems.title}
                  </SidebarLabel>
                </SidebarItem>
              )}
              {secondaryMenuItems?.items &&
                secondaryMenuItems.items.map((item, index) => {
                  return (
                    <Link to={item.url} className="cursor-pointer" key={index}>
                      <SidebarItem>
                        {item.icon && <item.icon className="mr-2 h-6" />}
                        <SidebarLabel>{item.name}</SidebarLabel>
                      </SidebarItem>
                    </Link>
                  );
                })}
            </SidebarSection>
          </SidebarBody>
          <SidebarFooter className="max-lg:hidden">
            <SidebarSection>
              <SidebarItem>
                <CodeBracketIcon />
                <SidebarLabel>Swagger</SidebarLabel>
              </SidebarItem>
              <SidebarItem>
                <BookOpenIcon />
                <SidebarLabel>Wiki</SidebarLabel>
              </SidebarItem>
            </SidebarSection>
            <SidebarDivider />
            <Dropdown>
              <DropdownButton as={SidebarItem}>
                <span className="flex min-w-0 items-center gap-3">
                  <Avatar
                    src={user?.profilePicture}
                    initials={`${user?.firstName[0]}${user?.lastName[0]}`}
                    className="size-12 bg-zinc-900 text-white"
                  />
                  <span className="min-w-0 w-full">
                    <span className="block truncate text-sm/5 font-medium text-zinc-950 ">
                      {user?.firstName} {user?.lastName}
                    </span>
                    <span className="block truncate text-xs/5 font-normal text-zinc-500 ">
                      {user?.email}
                    </span>
                  </span>
                </span>
              </DropdownButton>
              <DropdownMenu className="min-w-64" anchor="top start">
                <DropdownItem onClick={() => navigate('/my-profile')}>
                  <UserIcon />
                  <DropdownLabel>My profile</DropdownLabel>
                </DropdownItem>
                <DropdownItem href="/settings">
                  <Cog8ToothIcon />
                  <DropdownLabel>Settings</DropdownLabel>
                </DropdownItem>
                <DropdownDivider />
                <DropdownItem onClick={handleLogout}>
                  <ArrowRightStartOnRectangleIcon />
                  <DropdownLabel>Sign out</DropdownLabel>
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </SidebarFooter>
        </Sidebar>
      }
    >
      <Routes>
        {routes.map((route, index) => (
          <Route key={index} path={route.url} element={route.component} />
        ))}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </SidebarLayout>
  );
};

export default SidebarMenu;
