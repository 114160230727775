import axios from 'axios';
import { jwtDecode } from 'jwt-decode';

import { JwtPayload } from '../types/jwtPayload';

export class ApiClient {
  private static instance: ApiClient;

  private readonly baseUrl = process.env.REACT_APP_API_BASE_URL;

  private constructor() {
    axios.defaults.baseURL = this.baseUrl;
    axios.defaults.headers.post['Content-Type'] = 'application/json';
    axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
    axios.defaults.headers.common['Access-Control-Allow-Methods'] =
      'GET, POST, PUT, DELETE, PATCH, OPTIONS';

    axios.interceptors.request.use(
      async (config) => {
        const accessToken = localStorage.getItem('accessToken');

        // If token exists and is expired, refresh it
        if (accessToken && this.isTokenExpired(accessToken)) {
          try {
            const payload: JwtPayload = jwtDecode(accessToken);

            if (!payload.userId) {
              return Promise.reject(new Error('User ID not found'));
            }

            const response = await this.requestRefreshToken(payload.userId);

            if (!response.accessToken) {
              this.handleAccessTokenError();
              return Promise.reject(new Error('Failed to refresh token'));
            }

            const newAccessToken = response.accessToken;
            localStorage.setItem('accessToken', newAccessToken);
            config.headers['Authorization'] = `Bearer ${newAccessToken}`;
          } catch (error) {
            this.handleAccessTokenError();
            return Promise.reject(error);
          }
        }

        // Append the new or existing accessToken to the headers
        if (accessToken) {
          config.headers['Authorization'] = `Bearer ${accessToken}`;
        }

        return config;
      },
      (error) => Promise.reject(error),
    );

    axios.interceptors.response.use(
      (response) => {
        return response.data;
      },
      (error) => {
        console.error(error);

        return Promise.reject(error.response.data);
      },
    );
  }

  public static getInstance(): ApiClient {
    if (!ApiClient.instance) {
      ApiClient.instance = new ApiClient();
    }
    return ApiClient.instance;
  }

  public get(
    url: string,
    params?: unknown,
    responseType?: 'json' | 'blob' | 'text',
  ) {
    if (params) return axios.get(url, { params });
    if (responseType) return axios.get(url, { params, responseType });
    return axios.get(url);
  }

  public post(
    url: string,
    data: unknown,
    config?: import('axios').AxiosRequestConfig,
  ) {
    if (config) return axios.post(url, data, config);
    return axios.post(url, data);
  }

  public put(url: string, data?: unknown) {
    return axios.put(url, data);
  }

  public delete(url: string, params?: unknown) {
    return axios.delete(url, { params });
  }

  public deleteWithParams(url: string, data: unknown) {
    return axios.delete(url, { data });
  }

  public async requestRefreshToken(userId: string) {
    const response = await fetch(
      process.env.REACT_APP_API_BASE_URL + `/auth/refresh-token`,
      {
        method: 'POST',
        body: JSON.stringify({ userId }),
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );

    return response.json();
  }

  public isTokenExpired(token: string) {
    if (!token) return true;

    const { exp } = jwtDecode(token);
    return Date.now() >= (exp ?? 0) * 1000;
  }

  public handleAccessTokenError() {
    localStorage.removeItem('accessToken');
    window.location.href = '/login';
  }
}
