import { jwtDecode } from 'jwt-decode';
import { createContext, useContext, useState } from 'react';

import { IUser } from '../types/user';

export const AuthContext = createContext<{
  isLoggedIn: boolean;
  login: (accessToken: string) => void;
  logout: () => void;
  user: IUser | null;
}>({
  isLoggedIn: false,
  login: () => {},
  logout: () => {},
  user: null,
});

export const useAuth = () => {
  return useContext(AuthContext);
};

export const authService = {
  login: (accessToken: string) => {
    localStorage.setItem('accessToken', accessToken);
  },
  logout: () => {
    localStorage.removeItem('accessToken');
  },
  isAuthenticated: () => {
    return localStorage.getItem('accessToken') !== null;
  },
  getUser: () => {
    const accessToken = localStorage.getItem('accessToken');
    if (!accessToken) return null;
    try {
      return jwtDecode(accessToken) as IUser;
    } catch (error) {
      return null;
    }
  },
  refreshToken: (refreshToken: string) => {
    localStorage.setItem('refreshToken', refreshToken);
  },
};

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(authService.isAuthenticated());
  const [user, setUser] = useState<IUser | null>(
    authService.getUser() as IUser | null,
  );

  const login = (accessToken: string) => {
    authService.login(accessToken);
    setIsLoggedIn(true);
    setUser(authService.getUser() as IUser | null);
  };

  const logout = () => {
    authService.logout();
    setIsLoggedIn(false);
    setUser(null);
  };

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn,
        login,
        logout,
        user,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
