import React from 'react';

import { TimePeriod } from '../types/statisticsTypes';
import TileStatisticsBadge from './TileStatisticsBadge';

export interface TileProps {
  title: string;
  value: number;
  change?: number;
  timePeriod?: TimePeriod;
}

const Tile = ({ title, value, change, timePeriod }: TileProps) => {
  return (
    <div className="border-t-2 border-zinc-200 pt-8">
      <h3 className="text-md pb-4 font-normal">{title}</h3>
      <p className="text-4xl font-medium mt-2">{value}</p>
      {change !== undefined && change !== null && timePeriod && (
        <TileStatisticsBadge
          value={change}
          change={change}
          timePeriod={timePeriod}
          isChange
        />
      )}
    </div>
  );
};

export default Tile;
