import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Button } from '../components/catalyst-ui/button';
import { Field, Fieldset, Label } from '../components/catalyst-ui/fieldset';
import { Input } from '../components/catalyst-ui/input';
import { registerRequest } from '../services/AuthService';
import {
  CustomErrorResponse,
  handleErrorMessage,
} from '../utils/handleErrorMessage';

const Register = () => {
  const navigate = useNavigate();
  const [form, setForm] = useState({
    email: '',
    password: '',
    confirmPassword: '',
    firstName: '',
    lastName: '',
  });

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (form.password !== form.confirmPassword) {
      toast.error('Passwords do not match');
      return;
    }

    try {
      await registerRequest(
        form.email,
        form.password,
        form.firstName,
        form.lastName,
      );

      navigate('/login');
      toast.success('Account created successfully');
    } catch (error) {
      toast.error(handleErrorMessage(error as CustomErrorResponse));
    }
  };

  return (
    <div>
      <section className="bg-gradient-to-b from-zinc-100 to-zinc-200">
        <div className="h-screen flex items-center justify-center">
          <div className="w-full mx-2 col-span-6 max-w-3xl bg-white rounded-lg shadow-xl">
            <div className="p-6 space-y-4 lg:space-y-6 sm:p-8">
              <h1 className="text-xl font-bold leading-tight flex items-center gap-2 tracking-tight text-zinc-900 sm:text-2xl">
                Sign up
              </h1>
              <form onSubmit={handleSubmit}>
                <Fieldset className="space-y-6">
                  <Field>
                    <Label>Email</Label>
                    <Input
                      type="email"
                      name="email"
                      id="email"
                      size={22}
                      placeholder="Enter your email"
                      required
                      value={form.email}
                      onChange={(e) =>
                        setForm({ ...form, email: e.target.value })
                      }
                    />
                  </Field>
                  <div className="flex flex-col sm:flex-row gap-4">
                    <Field className="w-full ">
                      <Label>Password</Label>
                      <Input
                        type="password"
                        name="password"
                        id="password"
                        size={22}
                        placeholder="Enter your password"
                        required
                        value={form.password}
                        onChange={(e) =>
                          setForm({ ...form, password: e.target.value })
                        }
                      />
                    </Field>
                    <Field className="w-full">
                      <Label>Confirm Password</Label>
                      <Input
                        type="password"
                        name="confirmPassword"
                        id="confirmPassword"
                        size={22}
                        placeholder="Confirm your password"
                        required
                        value={form.confirmPassword}
                        onChange={(e) =>
                          setForm({ ...form, confirmPassword: e.target.value })
                        }
                      />
                    </Field>
                  </div>

                  <div className="flex flex-col sm:flex-row gap-4">
                    <Field className="w-full">
                      <Label>First Name</Label>
                      <Input
                        type="text"
                        name="firstName"
                        id="firstName"
                        size={22}
                        placeholder="Enter your first name"
                        required
                        value={form.firstName}
                        onChange={(e) =>
                          setForm({ ...form, firstName: e.target.value })
                        }
                      />
                    </Field>
                    <Field className="w-full">
                      <Label>Last Name</Label>
                      <Input
                        type="text"
                        name="lastName"
                        id="lastName"
                        size={22}
                        placeholder="Enter your last name"
                        required
                        value={form.lastName}
                        onChange={(e) =>
                          setForm({ ...form, lastName: e.target.value })
                        }
                      />
                    </Field>
                  </div>

                  <Button type="submit" className="w-full cursor-pointer h-12">
                    Sign up
                  </Button>
                </Fieldset>
              </form>

              <p className="text-sm font-light text-zinc-500">
                Already have an account?{' '}
                <Link
                  to="/login"
                  className="font-bold text-zinc-800 hover:underline"
                >
                  Sign in here
                </Link>
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Register;
