/**
 * Formats a timestamp into a human-readable date string.
 * @param {string} timestamp - The timestamp to format.
 * @returns {string} The formatted date string.
 */
const formatTimestamp = (timestamp: string): string => {
  const date = new Date(timestamp);
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  };
  return date.toLocaleDateString('en-US', options);
};

export default formatTimestamp;
