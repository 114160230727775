import { toast } from 'react-toastify';

import { ApiClient } from '../apiClient/apiClient';
import { UserResponse, UsersRequest } from '../types/user';

const apiClient = ApiClient.getInstance();

export const userService = {
  async getUserById(id: string): Promise<UserResponse> {
    try {
      return (await apiClient.get(`/user/id`, {
        id,
      })) as unknown as Promise<UserResponse>;
    } catch (error) {
      toast.error(`Error fetching user with id ${id}:`);
      throw error;
    }
  },

  async getUsers(
    page: number,
    pageSize: number = 20,
    searchTerm?: string,
  ): Promise<UsersRequest> {
    try {
      const params = { page, pageSize, searchTerm };
      return (await apiClient.get(`/user`, params)) as unknown as UsersRequest;
    } catch (error) {
      const errorMessage = searchTerm
        ? `Error searching users with term ${searchTerm} on page ${page}:`
        : `Error fetching users for page ${page}:`;
      toast.error(errorMessage);
      throw error;
    }
  },
};
