import { useGoogleLogin } from '@react-oauth/google';
import React from 'react';

import { ReactComponent as GoogleLogoIcon } from '../assets/icons/google-logo.svg';
import { Button } from './catalyst-ui/button';

export type GoogleLoginButtonProps = {
  onClick: (codeResponse: { code: string }) => void;
};

const GoogleLoginButton = ({ onClick }: GoogleLoginButtonProps) => {
  const handleGoogleLogin = useGoogleLogin({
    onSuccess: (codeResponse) => {
      onClick(codeResponse);
    },
    flow: 'auth-code',
  });

  return (
    <Button
      outline
      className="w-full h-12 cursor-pointer"
      onClick={handleGoogleLogin}
    >
      <GoogleLogoIcon className="w-4 h-4" />
      Log in with Google
    </Button>
  );
};

export default GoogleLoginButton;
