import { toast } from 'react-toastify';

import { ApiClient } from '../apiClient/apiClient';
import { Click, ClickListDTO } from '../types/click';

export const getClickList = (
  limit = 10,
  page: number,
): Promise<ClickListDTO> => {
  const apiClient = ApiClient.getInstance();

  return apiClient.get('clicker/paginated-clicks', {
    sortOrder: 'asc',
    sortField: 'timestamp',
    limit: limit,
    page: page,
  }) as unknown as Promise<ClickListDTO>;
};

export const getClickById = (id: string): Promise<Click> => {
  const apiClient = ApiClient.getInstance();
  try {
    return apiClient.get(`clicker/id`, { id }) as unknown as Promise<Click>;
  } catch (error) {
    toast.error(`Error fetching click with id ${id}`);
    throw error;
  }
};
