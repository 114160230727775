export type CustomErrorResponse = {
  error: string;
  message: string | string[];
  statusCode: number;
};

/**
 * Handles the error message based on the error object.
 * @param {CustomErrorResponse} error - The error object to be handled.
 * @returns {string} The formatted error message.
 */
export const handleErrorMessage = (error: CustomErrorResponse) => {
  if (!error.message) {
    return 'Unknown error occurred';
  }

  if (Array.isArray(error.message) && error.message.length > 0) {
    const capitalizedMessages = error.message.map((message) => {
      if (typeof message === 'string') {
        return message.charAt(0).toUpperCase() + message.slice(1);
      }
      return '';
    });

    return capitalizedMessages.filter(Boolean).join(', ');
  }

  if (typeof error.message === 'string') {
    return error.message.charAt(0).toUpperCase() + error.message.slice(1);
  }

  return 'Unknown error occurred';
};
