import { ApiClient } from '../apiClient/apiClient';
import { ArticleRequestResponse, ArticleResponse } from '../types/articleTypes';

const apiClient = ApiClient.getInstance();

export const articleService = {
  async getArticleById(id: string): Promise<ArticleResponse> {
    try {
      return (await apiClient.get(`/article/id`, {
        id,
      })) as unknown as Promise<ArticleResponse>;
    } catch (error) {
      console.error(`Error fetching article with id ${id}:`, error);
      throw error;
    }
  },

  async getArticles(
    page: number,
    pageSize: number,
    searchTerm: string,
    country: 'HU' | 'CZ' | 'SK' | 'ALL' | '',
  ): Promise<ArticleRequestResponse> {
    if (country === 'ALL') {
      country = '';
    }

    try {
      return (await apiClient.get(`/article`, {
        page,
        pageSize,
        searchTerm,
        country,
      })) as unknown as Promise<ArticleRequestResponse>;
    } catch (error) {
      console.error(`Error fetching articles for page ${page}:`, error);
      throw error;
    }
  },
};
