import { useEffect, useState } from 'react';

import PaginationComponent from '../components/PaginationComponent';
import TableComponent from '../components/TableComponent';
import { getClickList } from '../services/ClickService';
import { Click } from '../types/click';
import formatTimestamp from '../utils/fromatTimeStamp';

const Clicks = () => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [clicks, setClicks] = useState<Click[]>([]);
  const [totalResults, setTotalResults] = useState<number>(0);

  useEffect(() => {
    getClickList(10, currentPage).then((resp) => {
      console.log(resp);
      setClicks(resp.data);
      setTotalResults(resp.totalResults);
    });
  }, [currentPage]);

  const clickHeaders = [
    'ID',
    'Post ID',
    'Needs Aggregation',
    'Timestamp',
    'User ID',
    'User Token',
  ];

  const getClickRow = () => {
    return clicks.map((click) => {
      return {
        data: [
          click._id,
          click.postTitle,
          click.needsAggregation ? 'Yes' : 'No',
          formatTimestamp(click.timestamp),
          click.userId,
          click.userToken,
        ],
        url: `/clicks/${click._id}`,
      };
    });
  };

  return (
    <div>
      <TableComponent
        headers={clickHeaders}
        rows={getClickRow()}
        className="mt-10"
        title="Clicks"
      />
      <PaginationComponent
        currentPage={currentPage}
        itemPerPage={10}
        totalResults={totalResults}
        onBackButtonClick={() => setCurrentPage(currentPage - 1)}
        onNextButtonClick={() => setCurrentPage(currentPage + 1)}
        onPageChange={(page) => setCurrentPage(page)}
      />
    </div>
  );
};

export default Clicks;
