import React, { useState } from 'react';
import { toast } from 'react-toastify';

import { Avatar } from '../components/catalyst-ui/avatar';
import { Button } from '../components/catalyst-ui/button';
import { Field, Fieldset, Label } from '../components/catalyst-ui/fieldset';
import { Input } from '../components/catalyst-ui/input';
import { useAuth } from '../context/AuthContext';
import { changePasswordRequest } from '../services/AuthService';
import {
  CustomErrorResponse,
  handleErrorMessage,
} from '../utils/handleErrorMessage';

const MyProfile = () => {
  const { user } = useAuth();
  const [changePasswordForm, setChangePasswordForm] = useState({
    oldPassword: '',
    newPassword: '',
  });

  const handlePasswordChange = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const { oldPassword, newPassword } = changePasswordForm;

    if (!user) return;

    try {
      const response = await changePasswordRequest(
        oldPassword,
        newPassword,
        user.email,
      );

      console.log(response);
    } catch (error) {
      toast.error(handleErrorMessage(error as CustomErrorResponse));
    }
  };

  return (
    <div>
      <div className="flex flex-col items-center justify-start gap-12">
        <div className="relative">
          <Avatar
            src={user?.profilePicture}
            initials={`${user?.firstName[0]}${user?.lastName[0]}`}
            className="size-28 bg-zinc-900 text-white"
          />
        </div>

        <div className="text-center">
          <h1 className="text-3xl font-bold">
            {user?.firstName} {user?.lastName}
          </h1>
          <p className="text-md text-gray-500">{user?.email}</p>

          {/* Change password section */}
        </div>
      </div>
      <form onSubmit={handlePasswordChange}>
        <Fieldset className="mx-auto w-full max-w-3xl mt-20 border-2 border-gray-200 rounded-lg p-6">
          <h2 className="text-2xl font-bold mb-6">Change Password</h2>
          <div className="flex flex-col gap-10 sm:flex-row">
            <Field className="w-full">
              <Label>Current Password</Label>
              <Input
                type="password"
                className="w-full"
                value={changePasswordForm.oldPassword}
                onChange={(e) =>
                  setChangePasswordForm({
                    ...changePasswordForm,
                    oldPassword: e.target.value,
                  })
                }
              />
            </Field>

            <Field className="w-full">
              <Label>New Password</Label>
              <Input
                type="password"
                className="w-full"
                value={changePasswordForm.newPassword}
                onChange={(e) =>
                  setChangePasswordForm({
                    ...changePasswordForm,
                    newPassword: e.target.value,
                  })
                }
              />
            </Field>
          </div>

          <Button className="mt-4 h-12 cursor-pointer w-full" type="submit">
            Change Password
          </Button>
        </Fieldset>
      </form>
    </div>
  );
};

export default MyProfile;
