import { GoogleOAuthProvider } from '@react-oauth/google';
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { ReactComponent as ForbesLogoIcon } from '../assets/icons/f-logo.svg';
import { Button } from '../components/catalyst-ui/button';
import { Field, Fieldset, Label } from '../components/catalyst-ui/fieldset';
import { Input } from '../components/catalyst-ui/input';
import GoogleLoginButton from '../components/GoogleLoginButton';
import { useAuth } from '../context/AuthContext';
import { googleLoginRequest, loginRequest } from '../services/AuthService';

const Login = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const { login } = useAuth();
  const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;

  const loginProcess = async (accessToken: string) => {
    login(accessToken);
    navigate('/');
  };

  const handleLogin = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      const loginResponse = await loginRequest(email, password);
      const { accessToken } = loginResponse;
      loginProcess(accessToken);
    } catch (error) {
      toast.error('Invalid email or password');
    }
  };

  const handleGoogleLogin = async (codeResponse: { code: string }) => {
    try {
      const response = await googleLoginRequest(codeResponse.code);
      const { accessToken } = response;
      loginProcess(accessToken);
    } catch (error) {
      toast.error('Failed to login with Google');
    }
  };

  return (
    <>
      <section className="bg-gradient-to-b from-zinc-100 to-zinc-200">
        <div className="h-screen flex items-center justify-center">
          <div className="w-full mx-2 col-span-6 max-w-lg bg-white rounded-lg shadow-xl">
            <div className="p-6 space-y-4 lg:space-y-6 sm:p-8">
              <h1 className="text-xl font-bold leading-tight flex items-center gap-2 tracking-tight text-zinc-900 sm:text-2xl">
                <ForbesLogoIcon />
                AI Dashboard
              </h1>

              <form onSubmit={handleLogin}>
                <Fieldset className="space-y-3">
                  <Field>
                    <Label>Email</Label>
                    <Input
                      type="email"
                      name="email"
                      id="email"
                      size={22}
                      placeholder="Enter your email"
                      required
                      onChange={(e) => setEmail(e.target.value)}
                      value={email}
                    />
                  </Field>
                  <Field>
                    <Label>Password</Label>
                    <Input
                      type="password"
                      name="password"
                      id="password"
                      size={22}
                      placeholder="Enter your password"
                      required
                      onChange={(e) => setPassword(e.target.value)}
                      value={password}
                    />
                  </Field>
                  <div className="flex items-center justify-end">
                    <Link
                      to="/forgot-password"
                      className="text-sm hover:underline font-medium text-zinc-800"
                    >
                      Forgot password?
                    </Link>
                  </div>
                  <Button type="submit" className="w-full cursor-pointer h-12">
                    Sign in to your account
                  </Button>

                  {googleClientId && (
                    <>
                      <div className="flex items-center">
                        <div className="w-full h-0.5 bg-zinc-200"></div>
                        <div className="px-5 text-center text-zinc-500">or</div>
                        <div className="w-full h-0.5 bg-zinc-200"></div>
                      </div>

                      <div className="items-center space-y-3">
                        <GoogleOAuthProvider clientId={googleClientId}>
                          <GoogleLoginButton onClick={handleGoogleLogin} />
                        </GoogleOAuthProvider>
                      </div>
                    </>
                  )}
                  <p className="text-sm font-light text-zinc-500">
                    Don’t have an account yet?{' '}
                    <Link
                      to="/register"
                      className="font-bold text-zinc-800 hover:underline"
                    >
                      Sign up here
                    </Link>
                  </p>
                </Fieldset>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Login;
