/**
 * Capitalizes the first letter of a string while keeping the rest of the string unchanged.
 * @param {string} str - The string to capitalize.
 * @returns {string} The capitalized string.
 */
const capitalizeFirstLetter = (str: string): string => {
  if (!str) return str;
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export default capitalizeFirstLetter;
