import React from 'react';

import { TimePeriod } from '../types/statisticsTypes';
import { Badge } from './catalyst-ui/badge';
import { Text } from './catalyst-ui/text';

interface StatisticsBadgeProps {
  value: number | string;
  change?: number;
  timePeriod: TimePeriod;
  isChange?: boolean;
}

const formattingDaysLabel = (timePeriod: TimePeriod) => {
  switch (timePeriod) {
    case '1Day':
      return '1 day';
    case '7Days':
      return '7 days';
    case '30Days':
      return '30 days';
    case '90Days':
      return '90 days';
  }
};

const StatisticsBadge: React.FC<StatisticsBadgeProps> = ({
  value,
  change,
  timePeriod,
  isChange = false,
}) => {
  return (
    <div className="flex items-center gap-2 pt-4">
      <Badge
        color={
          isChange && change !== undefined
            ? change > 0
              ? 'lime'
              : 'pink'
            : undefined
        }
      >
        {isChange ? `${change}%` : value}
      </Badge>
      <Text>Since last {formattingDaysLabel(timePeriod)}</Text>
    </div>
  );
};

export default StatisticsBadge;
