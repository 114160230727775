import clsx from 'clsx';
import React, { useState } from 'react';

interface TooltipProps {
  children: React.ReactNode;
  content: React.ReactNode;
  position?: 'top' | 'right' | 'bottom' | 'left';
  className?: string;
  minWidth?: string;
}

const Tooltip: React.FC<TooltipProps> = ({
  children,
  content,
  position = 'top',
  className,
  minWidth = '150px',
}) => {
  const [visible, setVisible] = useState(false);

  return (
    <div
      className="relative w-full inline-flex" // Ensure Tooltip takes the full width
      onMouseEnter={() => setVisible(true)}
      onMouseLeave={() => setVisible(false)}
    >
      <span className="w-full">{children}</span>{' '}
      {/* Force full width on child wrapper */}
      {visible && (
        <div
          role="tooltip"
          className={clsx(
            'absolute z-10 p-2 text-sm  text-zinc-900 rounded-lg shadow-lg  border border-zinc-950/20 bg-zinc-200',
            {
              'bottom-full left-1/2 transform -translate-x-1/2 mb-2':
                position === 'top',
              'top-full left-1/2 transform -translate-x-1/2 mt-2':
                position === 'bottom',
              'right-full top-1/2 transform -translate-y-1/2 mr-2':
                position === 'left',
              'left-full top-1/2 transform -translate-y-1/2 ml-2':
                position === 'right',
            },
            className,
          )}
          style={{ minWidth, whiteSpace: 'pre-wrap', wordBreak: 'keep-all' }}
        >
          {content}
        </div>
      )}
    </div>
  );
};

export default Tooltip;
