import { ApiClient } from '../apiClient/apiClient';
import { AnalyticsResponse } from '../types/analytics';

const apiClient = ApiClient.getInstance();

export const getAnalyticsData = async (
  country: 'CZ' | 'SK' | 'HU',
  range: '1' | '7' | '90',
  page: number,
): Promise<AnalyticsResponse> => {
  return (await apiClient.get(`/article/by-views`, {
    page,
    limit: 10,
    language: country,
    viewPeriod: range,
  })) as unknown as Promise<AnalyticsResponse>;
};
