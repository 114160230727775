import { ApiClient } from '../apiClient/apiClient';
import {
  PageviewsByArticleIdResponse,
  StatisticsApiResponse,
} from '../types/statisticsTypes';

const apiClient = ApiClient.getInstance();

export const statisticsService = {
  async getStatistics(): Promise<StatisticsApiResponse> {
    try {
      return (await apiClient.get(
        '/statistics/statistics',
      )) as unknown as Promise<StatisticsApiResponse>;
    } catch (error) {
      console.error('Error fetching article statistics:', error);
      throw error;
    }
  },

  async getPageviewsByArticleId(
    id: string,
  ): Promise<PageviewsByArticleIdResponse> {
    return (await apiClient.get(`/statistics/article-pageviews-statistics`, {
      postId: id,
    })) as unknown as Promise<PageviewsByArticleIdResponse>;
  },
};
