import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { Heading } from '../components/catalyst-ui/heading';
import { Input } from '../components/catalyst-ui/input';
import PaginationComponent from '../components/PaginationComponent';
import TableComponent from '../components/TableComponent';
import Tooltip from '../components/Tooltip';
import { userService } from '../services/UserService';
import { UserResponse } from '../types/user';

const Users = () => {
  const searchParams = new URLSearchParams(location.search);
  const pageParam = searchParams.get('page');
  const [users, setUsers] = useState<UserResponse[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string>('');
  const [currentPage, setCurrentPage] = useState<number>(
    pageParam ? parseInt(pageParam, 10) : 1,
  );
  const [totalResults, setTotalResults] = useState<number>(0);

  //change here if user data gets changed in the backend
  const userHeaders = [
    'User Token',
    'User ID',
    'Articles',
    'Categories',
    'Tags',
  ];

  const fetchUsers = async (page: number, searchTerm?: string) => {
    try {
      const { data, totalResults } = await userService.getUsers(
        page,
        10,
        searchTerm,
      );

      setUsers(data);
      setTotalResults(totalResults);
      setTotalResults(10000);
    } catch (error) {
      toast.error('Error fetching users');
    }
  };

  useEffect(() => {
    fetchUsers(currentPage, debouncedSearchTerm);
  }, [currentPage, debouncedSearchTerm]);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm]);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchTerm(value);
    setCurrentPage(1);
    setTotalResults(0);
    if (value.trim().length === 0) {
      fetchUsers(1);
    }
  };

  // change here if user data gets changed in the backend
  const userRows = (users ?? []).map((user) => {
    return {
      data: [
        user.userToken ?? '-',
        user.userId ?? '-',
        user.postIds?.length ?? 0, // articles
        user.categories?.length ?? 0, // categories
        user.tags?.length ?? 0, // tags
      ],
      url: `/users/${user._id}`,
    };
  });

  return (
    <div>
      <div className="flex flex-col w-full flex-wrap justify-between gap-4 border-b border-zinc-100/100 pb-6">
        <Heading>Users</Heading>
        <div className="flex items-center max-w-60 gap-4">
          <Tooltip
            content="Search for users by id, category id, post id, tag id or user token"
            position="bottom"
            minWidth="250px"
          >
            <Input placeholder="Search..." onChange={handleSearchChange} />
          </Tooltip>
        </div>
      </div>

      {users?.length > 0 ? (
        <>
          <TableComponent headers={userHeaders} rows={userRows} />
          <PaginationComponent
            currentPage={currentPage}
            itemPerPage={10}
            totalResults={totalResults}
            onBackButtonClick={() => setCurrentPage(currentPage - 1)}
            onNextButtonClick={() => setCurrentPage(currentPage + 1)}
            onPageChange={(page) => setCurrentPage(page)}
          />
        </>
      ) : (
        <div className="text-center text-sm/5 text-zinc-500 min-h-[75vh] flex items-center justify-center">
          No users found
        </div>
      )}
    </div>
  );
};

export default Users;
