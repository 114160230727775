import { ApiClient } from '../apiClient/apiClient';

const apiClient = ApiClient.getInstance();

export const loginRequest = (
  email: string,
  password: string,
): Promise<{
  accessToken: string;
  firstName: string;
  lastName: string;
  email: string;
}> => {
  return apiClient.post('auth/login', {
    email,
    password,
  }) as unknown as Promise<{
    accessToken: string;
    firstName: string;
    lastName: string;
    email: string;
  }>;
};

export const registerRequest = (
  email: string,
  password: string,
  firstName: string,
  lastName: string,
) => {
  return apiClient.post('auth/register', {
    email,
    password,
    firstName,
    lastName,
  });
};

export const googleLoginRequest = (token: string) => {
  return apiClient.post('auth/google-login', {
    token,
  }) as unknown as Promise<{
    accessToken: string;
  }>;
};

export const changePasswordRequest = (
  oldPassword: string,
  newPassword: string,
  email: string,
) => {
  return apiClient.post('auth/change-password', {
    oldPassword: oldPassword,
    newPassword: newPassword,
    email: email,
  });
};
