import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  Pagination,
  PaginationGap,
  PaginationList,
  PaginationNext,
  PaginationPage,
  PaginationPrevious,
} from './catalyst-ui/pagination';

export interface PaginationComponentProps {
  className?: string;
  currentPage: number;
  itemPerPage: number;
  totalResults: number;
  onBackButtonClick?: () => void;
  onNextButtonClick?: () => void;
  onPageChange?: (page: number) => void;
}

const getPaginationPages = (totalPages: number, currentPage: number) => {
  const pages = [];
  pages.push(1);

  const startPage = Math.max(currentPage - 2, 2);
  const endPage = Math.min(currentPage + 2, totalPages - 1);

  for (let i = startPage; i <= endPage; i++) {
    pages.push(i);
  }

  if (totalPages > 1) {
    pages.push(totalPages);
  }

  return pages;
};

const PaginationComponent = ({
  className,
  currentPage,
  itemPerPage,
  totalResults,
  onBackButtonClick,
  onNextButtonClick,
  onPageChange,
}: PaginationComponentProps) => {
  const location = useLocation();
  const navigate = useNavigate();

  const pageNumber = () => {
    return Math.ceil(totalResults / itemPerPage);
  };

  const pages = getPaginationPages(pageNumber(), currentPage);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    params.set('page', currentPage.toString());
    navigate({ search: params.toString() }, { replace: true });
  }, [currentPage]);

  return (
    <div className={className}>
      <Pagination className="mt-6">
        <PaginationPrevious
          onClick={onBackButtonClick}
          disabled={currentPage === 1}
        />
        <PaginationList>
          {pages.map((page, index) => {
            const isGap = index > 0 && page !== pages[index - 1] + 1;
            return (
              <React.Fragment key={page}>
                {isGap && <PaginationGap />}
                <PaginationPage
                  current={page === currentPage}
                  onClick={() => onPageChange?.(page)}
                >
                  {page}
                </PaginationPage>
              </React.Fragment>
            );
          })}
        </PaginationList>
        <PaginationNext
          onClick={onNextButtonClick}
          disabled={currentPage === pageNumber()}
        />
      </Pagination>
    </div>
  );
};

export default PaginationComponent;
