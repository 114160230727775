import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { Heading } from '../components/catalyst-ui/heading';
import {
  Listbox,
  ListboxLabel,
  ListboxOption,
} from '../components/catalyst-ui/listbox';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '../components/catalyst-ui/table';
import { Strong, Text } from '../components/catalyst-ui/text';
import PaginationComponent from '../components/PaginationComponent';
import { getAnalyticsData } from '../services/AnalyticService';
import { ArticleResponse } from '../types/articleTypes';

const Analytics = () => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [country, setCountry] = useState<'CZ' | 'SK' | 'HU' | null>(null);
  const [range, setRange] = useState<'1' | '7' | '90' | null>(null);
  const [analyticsData, setAnalyticsData] = useState<ArticleResponse[]>([]);
  const [totalResults, setTotalResults] = useState<number>(0);
  const ITEM_PER_PAGE = 10;

  useEffect(() => {
    setCurrentPage(1); // Reset currentPage to 1 whenever country or range changes
  }, [country, range]);

  useEffect(() => {
    fetchAnalyticsData();
  }, [country, range, currentPage]);

  const fetchAnalyticsData = async () => {
    if (!country || !range) {
      return;
    }

    try {
      const { data, totalResults } = await getAnalyticsData(
        country,
        range,
        currentPage,
      );
      setAnalyticsData(data);
      setTotalResults(totalResults);
    } catch (error) {
      console.error(
        `Error fetching analytics data for country ${country} and range ${range}:`,
        error,
      );
      toast.error(
        `Error fetching analytics data for country ${country} and range ${range}:`,
      );
    }
  };

  const getPageViewsByRange = (article: ArticleResponse) => {
    console.log(article);
    switch (range) {
      case '1':
        return article.pageviews1;
      case '7':
        return article.pageviews7;
      case '90':
        return article.pageviews90;
      default:
        return 0;
    }
  };

  return (
    <div>
      <div className="flex flex-col sm:flex-row w-full flex-wrap sm:items-end sm:justify-between gap-4 pb-6 ">
        <Heading>Analytics</Heading>
        <div className="flex gap-4">
          <Listbox
            name="country"
            onChange={setCountry}
            placeholder="Select country&hellip;"
            value={country}
          >
            <ListboxOption value="CZ">
              <ListboxLabel>Czech Republic</ListboxLabel>
            </ListboxOption>
            <ListboxOption value="SK">
              <ListboxLabel>Slovakia</ListboxLabel>
            </ListboxOption>
            <ListboxOption value="HU">
              <ListboxLabel>Hungary</ListboxLabel>
            </ListboxOption>
          </Listbox>
          <Listbox
            name="range"
            onChange={setRange}
            placeholder="Select range&hellip;"
            value={range ?? null}
          >
            <ListboxOption value="1">
              <ListboxLabel>1 day</ListboxLabel>
            </ListboxOption>
            <ListboxOption value="7">
              <ListboxLabel>7 days</ListboxLabel>
            </ListboxOption>
            <ListboxOption value="90">
              <ListboxLabel>90 days</ListboxLabel>
            </ListboxOption>
          </Listbox>
        </div>
      </div>

      {country && range && analyticsData.length > 0 ? (
        <>
          <Table>
            <TableHead>
              <TableCell>No.</TableCell>
              <TableCell>Article title</TableCell>
              <TableCell>Views</TableCell>
            </TableHead>
            <TableBody>
              {analyticsData?.map((item, index) => (
                <TableRow key={item.postId} href={`/articles/${item.postId}`}>
                  <TableCell className="w-50">
                    {(currentPage - 1) * ITEM_PER_PAGE + index + 1}
                  </TableCell>
                  <TableCell className="w-full">{item.title}</TableCell>
                  <TableCell className="w-50">
                    {getPageViewsByRange(item)}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>

          <PaginationComponent
            currentPage={currentPage}
            totalResults={totalResults}
            itemPerPage={ITEM_PER_PAGE}
            onBackButtonClick={() => setCurrentPage(currentPage - 1)}
            onNextButtonClick={() => setCurrentPage(currentPage + 1)}
            onPageChange={(page) => setCurrentPage(page)}
          />
        </>
      ) : (
        <div className="mt-24 flex justify-center items-center text-center">
          <Text className="!text-base/10">
            Please select a <Strong>country</Strong> from the list and specify a{' '}
            <Strong>range</Strong> of days for your analysis.
          </Text>
        </div>
      )}
    </div>
  );
};

export default Analytics;
